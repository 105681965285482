import { PptColorEnum, PptLayoutEnum } from '../enums';
import {
  addImage,
  addMasterSlide,
  addRectangle,
  addSquare,
  addText,
  buildEducation,
  buildExperiences,
  buildIndustries,
  buildJobTitle,
  buildLanguages,
  buildNameSurname,
  buildSkillsText,
  fillAvatar,
} from '../helper';
import { getPlaceholders } from '../placeholders';
import {
  Ppt,
  PptFillFn,
  PptLayoutBuilderMethod,
  PptLongLayoutColors,
  PptObjectProps,
  PptSlide,
  PptSlideMasterObjectsProps,
  PptTextProps,
  PptTextPropsOptions,
} from '../types';
import { loadAzureBlob } from '~/shared/api/loadAzureBlob';
import { StatusTypes } from '~/shared/enums/status';
import { Employee, EmployeeCertification } from '~/shared/models/api/employee';

const {
  avatar,
  certificateLogo,
  certificateText,
  education,
  experience,
  industries,
  languages,
  nameSurname,
  jobTitle,
  skills,
} = getPlaceholders();

const MASTER_SLIDE_PAGE_1 = 'MASTER_SLIDE_PAGE_1';
const MASTER_SLIDE_PAGE_2 = 'MASTER_SLIDE_PAGE_2';

const addExperienceTitle = (slide: PptSlide, options: PptTextPropsOptions) => {
  slide.addText('Professional Experience', {
    ...options,
    x: 4.49,
    y: 0.24,
    w: 4.35,
  });
};

const addTitles = (slide: PptSlide, options: PptTextPropsOptions) => {
  slide.addText('Expertise', { ...options, x: 9.3, y: 0.24 });
  slide.addText('Education', { ...options, x: 11.31, y: 0.24 });
  slide.addText('Languages', { ...options, x: 11.31, y: 2.3 });
  slide.addText('Industries', { ...options, x: 11.31, y: 3.46 });
  slide.addText('Certifications', { ...options, x: 9.2, y: 5.15 });
};

const buildCommonObjects = (
  colors: PptLongLayoutColors
): PptSlideMasterObjectsProps => {
  const { yellowOrBlue, yellowOrWhite, whiteOrBlue } = colors;

  return [
    addRectangle({ x: 0, y: 0, w: 4.08, h: 7.5 }, PptColorEnum.BLACK),
    addSquare({ x: 3.82, y: 1.32, size: 0.18 }, yellowOrBlue),
    addSquare({ x: 3.3, y: 1.57, size: 0.38 }, yellowOrBlue),
    addSquare({ x: 3.68, y: 1.95, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.87, y: 2.8, size: 0.34 }, yellowOrBlue),
    addSquare({ x: 3.43, y: 3.6, size: 0.32 }, yellowOrBlue),
    addSquare({ x: 3.78, y: 3.95, size: 0.18 }, yellowOrBlue),
    addSquare({ x: 3.1, y: 4.26, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.87, y: 4.64, size: 0.46 }, yellowOrBlue),
    addSquare({ x: 3.68, y: 5.19, size: 0.64 }, yellowOrBlue),
    addSquare({ x: 3.48, y: 5.86, size: 0.18 }, yellowOrBlue),

    addText('Our Experts', {
      bold: true,
      color: yellowOrWhite,
      fontSize: 24,
      position: { x: 0.33, y: 0.24, w: 2.5, h: 0.44 },
    }),
    jobTitle.add({
      bold: true,
      color: PptColorEnum.WHITE,
      fontSize: 13,
      position: { x: 0.78, y: 1.22, w: 2.22, h: 0.22 },
    }),

    avatar.add({ position: { x: 0.83, y: 1.71, w: 1.5, h: 1.5 } }),

    nameSurname.add({
      bold: true,
      color: whiteOrBlue,
      fontSize: 13,
      position: { x: 0.78, y: 3.45, w: 1.33, h: 0.3 },
    }),

    addImage('/company-logos/KIgroup_Logo_White.png', {
      x: 0.33,
      y: 7,
      w: 0.93,
      h: 0.24,
    }),
  ];
};

const buildFirstPageObjects = (
  colors: PptLongLayoutColors
): PptSlideMasterObjectsProps => {
  const commonObjects = buildCommonObjects(colors);

  if (!commonObjects) {
    return;
  }

  return [
    ...commonObjects,
    experience.add({
      fontSize: 8,
      position: { x: 4.59, y: 0.64, w: 4.35, h: 6.8 },
    }),
    skills.add({
      bullet: true,
      fontSize: 8,
      position: { x: 9.4, y: 0.64, w: 1.73, h: 0.44 },
    }),

    education.add({
      fontSize: 8,
      position: { x: 11.42, y: 0.64, w: 1.87, h: 1.8 },
    }),
    languages.add({
      fontSize: 8,
      position: { x: 11.42, y: 2.7, w: 1.87, h: 0.85 },
    }),
    industries.add({
      bullet: true,
      fontSize: 8,
      position: { x: 11.42, y: 3.86, w: 1.87, h: 0.85 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 0,
      position: { x: 9.3, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 1,
      position: { x: 10.18, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 2,
      position: { x: 11.06, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 3,
      position: { x: 11.96, y: 5.55, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 4,
      position: { x: 9.3, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 5,
      position: { x: 10.18, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 6,
      position: { x: 11.06, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateLogo.add({
      fontSize: 6,
      placeholderId: 7,
      position: { x: 11.96, y: 6.3, w: 0.7, h: 0.7 },
    }),
    certificateText.add({
      bold: true,
      fontSize: 8,
      position: { x: 9.3, y: 7.05, w: 3.36, h: 0.48 },
    }),
  ];
};

const buildSecondPageObjects = (
  colors: PptLongLayoutColors
): PptSlideMasterObjectsProps => {
  const commonObjects = buildCommonObjects(colors);

  if (!commonObjects) {
    return;
  }

  return [
    ...commonObjects,

    experience.add({
      fontSize: 8,
      position: { x: 4.59, y: 0.64, w: 8.5, h: 6.8 },
    }),
  ];
};

const addRoundedBox = (slide: PptSlide) => {
  slide.addShape('roundRect', {
    x: 0.78,
    y: 1.66,
    w: 1.7,
    h: 1.7,
    rectRadius: 0.1,
    fill: { color: PptColorEnum.GRAY_LIGHT },
    lineSize: 1,
  });
};

const fillCertificates = async (
  certifications: EmployeeCertification[],
  slide: PptSlide
) => {
  const approvedCertificates = certifications.filter(
    ({ status }) => status === StatusTypes.APPROVED
  );
  const type = 'cover';
  const objectProps: PptObjectProps = { sizing: { type, w: 0.7, h: 0.7 } };
  const texts: string[] = [];
  for (const index in approvedCertificates) {
    const { logo, name } = approvedCertificates[index].certificate;
    if (logo) {
      const path = await loadAzureBlob(logo);
      certificateLogo.fill({
        slide,
        placeholderId: Number(index),
        path,
        objectProps,
      });
    } else {
      texts.push(name);
    }
  }
  if (texts.length) {
    certificateText.fill({ slide, text: texts.join(', ') });
  }
};

const getDefaultTitleOptions = (
  options: PptTextPropsOptions
): PptTextPropsOptions => ({
  ...options,
  h: 0.44,
  w: 1.87,
  bold: true,
  fontSize: 12,
});

const fillCommonPlaceholders = async (
  experiences: PptTextProps[],
  profile: Employee,
  slide: PptSlide,
  titleOptions: PptTextPropsOptions
) => {
  addExperienceTitle(slide, titleOptions);
  addRoundedBox(slide);

  jobTitle.fill({ slide, text: buildJobTitle(profile) });
  nameSurname.fill({ slide, text: buildNameSurname(profile) });
  experience.fill({ slide, text: experiences });

  await fillAvatar({
    fill: avatar.fill,
    objectProps: { sizing: { type: 'cover', w: 1.6, h: 1.6 } },
    slide,
    url: profile.image_url,
  });
};

const fillFirstPage = async (
  experiences: PptTextProps[],
  ppt: Ppt,
  profile: Employee,
  sectionTitle: string,
  titleOptions: PptTextPropsOptions
) => {
  const slide = ppt.addSlide({ masterName: MASTER_SLIDE_PAGE_1, sectionTitle });

  addTitles(slide, titleOptions);
  await fillCommonPlaceholders(experiences, profile, slide, titleOptions);
  const skillsText = buildSkillsText(profile.main_skills);
  skills.fill({ slide, text: skillsText });
  education.fill({ slide, text: buildEducation(profile) });
  industries.fill({ slide, text: buildIndustries(profile) });
  languages.fill({ slide, text: buildLanguages(profile) });
  await fillCertificates(profile.certifications, slide);
};

const fillSecondPage = async (
  experiences: PptTextProps[],
  ppt: Ppt,
  profile: Employee,
  sectionTitle: string,
  titleOptions: PptTextPropsOptions
) => {
  const slide = ppt.addSlide({ masterName: MASTER_SLIDE_PAGE_2, sectionTitle });

  await fillCommonPlaceholders(experiences, profile, slide, titleOptions);
};

const fillSlides = async (
  colors: PptLongLayoutColors,
  ppt: Ppt,
  profile: Employee,
  title: string
) => {
  const ITEMS_FOR_EXPERIENCE = 2; // each experience has 2 items (titles & description)
  const ITEMS_ON_PAGE_1 = 4 * ITEMS_FOR_EXPERIENCE;
  const experiences = buildExperiences(profile);
  const titleOptions = getDefaultTitleOptions({ color: colors.blackOrBlue });
  const experiences1 = experiences.slice(0, ITEMS_ON_PAGE_1);
  const experiences2 = experiences.slice(ITEMS_ON_PAGE_1, experiences.length);

  await fillFirstPage(experiences1, ppt, profile, title, titleOptions);
  await fillSecondPage(experiences2, ppt, profile, title, titleOptions);
};

const fillSlideBuilder =
  (colors: PptLongLayoutColors, ppt: Ppt, title: string): PptFillFn =>
  async (profiles) => {
    for (let i = 0; i < profiles.length; i++) {
      await fillSlides(colors, ppt, profiles[i], title);
    }
  };

const getColors = (isKiGroup: boolean): PptLongLayoutColors => ({
  blackOrBlue: isKiGroup ? PptColorEnum.BLACK : PptColorEnum.BLUE,
  whiteOrBlue: isKiGroup ? PptColorEnum.WHITE : PptColorEnum.BLUE,
  yellowOrBlue: isKiGroup ? PptColorEnum.YELLOW : PptColorEnum.BLUE,
  yellowOrWhite: isKiGroup ? PptColorEnum.YELLOW : PptColorEnum.WHITE,
});

export const buildLongLayout: PptLayoutBuilderMethod = (ppt, layout, title) => {
  const colors = getColors(layout === PptLayoutEnum.LONG_KI_GROUP);

  addMasterSlide(buildFirstPageObjects(colors), ppt, MASTER_SLIDE_PAGE_1);
  addMasterSlide(buildSecondPageObjects(colors), ppt, MASTER_SLIDE_PAGE_2);

  return {
    title,
    fill: fillSlideBuilder(colors, ppt, title),
  };
};
