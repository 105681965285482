import {
  Badge,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import ProjectMapConfirmationModal from '~/administration/components/projects/ProjectMapConfirmationModal';
import useProjectMapModal from '~/administration/hooks/projects/useProjectMapModal';
import { Map } from '~/shared/components/ui/map/Map';
import ModalButton from '~/shared/components/ui/ModalButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectMapModalProps {
  title?: string;
  projectId?: number;
  isOpen: boolean;
  onClose: () => void;
}

const ProjectMapModal: FC<ProjectMapModalProps> = ({
  title,
  projectId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('projects');
  const {
    children,
    confirmationModal,
    isLoadingChildren,
    isLoadingPotentialChildren,
    isSaving,
    handleCancelConfirmation,
    handleClose,
    handleConfirmationSave,
    handleSave,
    wasChanged,
  } = useProjectMapModal({ projectId, onClose });

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay />
      <ProjectMapConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={handleCancelConfirmation}
        onConfirm={handleConfirmationSave}
        isLoading={isLoadingChildren}
        data={children}
        projectName={title}
      />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader mt="2" mb="0">
            <Badge colorScheme="blue" px={2}>
              {t(`map_modal.label`)}
            </Badge>
            <Heading fontSize="lg" fontWeight="bold" mt="2" color="gray.500">
              {t(`map_modal.title`)}
            </Heading>
            <Heading fontSize="md" fontWeight="bold" mt="2" color="gray.800">
              {title}
            </Heading>
          </ModalHeader>
        </Stack>
        <ModalCloseButton />
        <ModalBody>
          <Map
            childrenTitle={t(`map_modal.children_title`)}
            isLoadingChildren={isLoadingChildren}
            isLoadingPotentialChildren={isLoadingPotentialChildren}
            potentialChildrenTitle={t(`map_modal.potential_children_title`)}
          />
        </ModalBody>
        <ModalFooter justifyContent="end" gap={5}>
          <ModalButton type="button" onClick={handleClose}>
            {t(`map_modal.button.cancel`)}
          </ModalButton>
          <ModalButton
            colorScheme="blue"
            type="submit"
            variant="solid"
            onClick={handleSave}
            disabled={!wasChanged}
            isUpdating={isSaving}
          >
            {t(`map_modal.button.save`)}
          </ModalButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectMapModal;
