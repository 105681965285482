import { ProfileSectionHeader } from '../ProfileSectionHeader';
import { AboutItemIcon, IconPosition } from './AboutItemIcon';
import { ProfileBio } from './ProfileBio';
import { Box, Flex, Grid, Stack, Text, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/about/EditModal';
import LanguageList from '~/profile/components/about/languages/LanguageList';
import ProfilePicture from '~/profile/components/about/picture/ProfilePicture';
import SocialLinkSingle from '~/profile/components/about/social-links/SocialLinkSingle';
import useProfileStore from '~/profile/stores/profile';
import { ProfileCompanyLogo } from '~/shared/components/ProfileCompanyLogo';
import MissingData from '~/shared/components/ui/MissingData';
import { locationNameToKey } from '~/shared/mappers/locationNameToKey';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdLocationCity,
  MdLocationOn,
  MdMail,
  MdOutlineAssignmentInd,
  MdOutlineAssignmentLate,
  MdOutlineVerified,
  MdPerson,
} from 'react-icons/md';
import ReactTimeAgo from 'react-time-ago';

const About: React.FC = () => {
  const { t } = useTranslation(['base', 'profile']);
  const { profile } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lastUpdate = profile?.last_update && new Date(profile.last_update);
  const hasTeamLead = profile?.team !== null;
  const isVerified = profile?.verified_profile;
  const officeLocation =
    profile?.office && t(`base:locations.${locationNameToKey(profile.office)}`);

  const leaderText = `${t(`profile:about_me.team_lead`, {
    firstName: profile?.team?.manager.first_name,
    lastName: profile?.team?.manager.second_name,
  })}`;

  const workSince = t(`profile:about_me.experience_since_date`, {
    date: profile?.work_since || '-',
  });
  return (
    <>
      <Box data-testid="profile-component-about" mb={4}>
        <EditModal isOpen={isOpen} onClose={onClose} />
        <ProfileSectionHeader
          headingDataTestId="test-heading-about"
          sectionTitle={t(`profile:about_me.title`)}
          onClickEdit={onOpen}
        >
          {lastUpdate && (
            <Flex>
              <Text color="blue.800" m="0 10px" fontSize="xs">
                {t(`profile:about_me.last_update`)}
                <ReactTimeAgo date={lastUpdate} />
              </Text>
            </Flex>
          )}
        </ProfileSectionHeader>
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={4}>
          <Box>
            <ProfilePicture />
          </Box>
          <Stack gridColumnStart="2" gridColumnEnd="4">
            <Grid
              templateColumns={profile?.company ? '1fr 2fr' : '2fr'}
              gap={4}
              alignItems="end"
            >
              <ProfileCompanyLogo
                name={profile?.company?.name}
                path={profile?.company?.logo_url}
              />
            </Grid>

            <AboutItemIcon
              text={`${profile?.first_name} ${profile?.second_name}`}
              icon={isVerified ? MdOutlineVerified : MdOutlineAssignmentLate}
              boxSize={7}
              fontWeight="bold"
              fontSize="xl"
              iconColor={isVerified ? 'green.300' : 'orange.300'}
              iconPosition={IconPosition.RIGHT}
              tipLabel={t(
                isVerified ? `profile:verified` : `profile:not_verified`
              )}
              iconPaddingTop="1"
            />

            <Text>
              {profile?.job_title}
              {profile?.seniority && (
                <Text as="span" ml="1" fontSize="sm" color="gray.600">
                  {`(${profile?.seniority})`}
                </Text>
              )}
            </Text>
            <AboutItemIcon text={profile?.email} icon={MdMail} />
            <AboutItemIcon text={profile?.country} icon={MdLocationOn} />
            <AboutItemIcon text={officeLocation} icon={MdLocationCity} />
            {hasTeamLead && <AboutItemIcon text={leaderText} icon={MdPerson} />}
            <AboutItemIcon
              text={workSince}
              icon={MdOutlineAssignmentInd}
              testId="test-personal-experience"
            />
          </Stack>
          <ProfileBio profileBio={profile?.bio} />
        </Grid>
      </Box>
      <Grid templateColumns="repeat(2, 1fr)" alignContent="center" gap="5">
        <Box data-testid="profile-component-social-links">
          <Text color="blue.700" fontWeight="bold">
            {t(`profile:about_me.social_link.title`)}
          </Text>
          {!profile?.social_links.length ? (
            <MissingData text={t(`profile:about_me.social_link.not_added`)} />
          ) : (
            <Flex mt="2">
              {profile?.social_links.map((sl) => (
                <SocialLinkSingle
                  key={`social-link-network-${sl.network}-${sl.user_name}`}
                  socialLink={sl}
                />
              ))}
            </Flex>
          )}
        </Box>
        <Box>
          {!profile?.languages.length ? (
            <>
              <Text color="blue.700" fontWeight="bold">
                {t(`profile:about_me.languages.title`)}
              </Text>
              <MissingData text={t(`profile:about_me.languages.not_added`)} />
            </>
          ) : (
            <LanguageList languages={profile?.languages} />
          )}
        </Box>
      </Grid>
    </>
  );
};

export default About;
