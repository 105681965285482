import {
  deleteData,
  get,
  patch,
  putFormData,
  searchPaginated,
} from '~/shared/api/endpoints';
import { AxiosResponse } from '~/shared/api/fetcher';
import { employeesEndpoint } from '~/shared/api/urls/employees';
import { FormDataKey } from '~/shared/enums/formDataKey';
import {
  Employee,
  EmployeeBasicInfo,
  EmployeeExportFields,
  EmployeeFilterParams,
  EmployeeShortDescriptionPayload,
  EmployeeShortDescriptionProps,
  UpdateEmployeeImage,
  UpdateEmployeePermissions,
  UpdateEmployeeRolesPayload,
  UpdateEmployeeRolesProps,
  UpdatePermissions,
} from '~/shared/models/api/employee';
import { EmployeeProject } from '~/shared/models/api/project';
import { EmployeeFilters } from '~/shared/models/search/filters/employees';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { SearchProps } from '~/shared/models/search/search';
import { EmployeeIdOrMe } from '~/shared/types/employeeIdOrMe';
import { getSearchFiltersByParamsType } from '~/shared/utils/search';
import debounce from 'debounce-promise';

export const getEmployee = async (
  employeeIdOrMe: EmployeeIdOrMe
): Promise<Employee> => {
  const { data: employee } = await get<Employee>(
    employeesEndpoint.id(employeeIdOrMe).url
  );

  return employee;
};

export const updateEmployeeImage = async (
  employeeIdOrMe: EmployeeIdOrMe,
  updateImage: UpdateEmployeeImage
) => {
  const formData = new FormData();
  formData.append(FormDataKey.FILE, updateImage.image);
  formData.append(FormDataKey.EMAIL, updateImage.email);

  return putFormData(
    employeesEndpoint.id(employeeIdOrMe).picture.url,
    formData
  );
};

export const deleteEmployeeImage = async (
  employeeId: number
): Promise<unknown> => {
  const { data } = await deleteData(
    employeesEndpoint.id(employeeId).picture.url
  );
  return data;
};

export const searchEmployees = debounce(
  async (
    value: SearchProps | EmployeeFilters
  ): Promise<PaginatedResponse<Employee>> => {
    const searchFilters = getSearchFiltersByParamsType<EmployeeFilters>(value);

    const { data } = await searchPaginated<Employee>(
      employeesEndpoint.url,
      searchFilters
    );

    return data;
  },
  500
);

export const getEmployeesByIds = debounce(
  async (employeeIds: number[]): Promise<Employee[]> => {
    const { data } = await get<Employee[]>(employeesEndpoint.search.url, {
      params: { employee_id_list: employeeIds },
    });

    return data;
  },
  500
);

export const getEmployeesBasicInfo = debounce(
  async (query: string): Promise<EmployeeBasicInfo[]> => {
    const { data } = await get<EmployeeBasicInfo[]>(
      employeesEndpoint.basicInfo.url,
      { params: { query } }
    );

    return data;
  },
  500
);

export const updateEmployee = async (
  employeeId: EmployeeIdOrMe,
  partialEmployee: Partial<Employee>
): Promise<Employee> => {
  const { data: employee } = await patch<Employee>(
    employeesEndpoint.id(employeeId).url,
    partialEmployee
  );

  return employee;
};

export const updateEmployeeRoles = async ({
  employeeId,
  roles,
}: UpdateEmployeeRolesProps): Promise<UpdateEmployeeRolesPayload> => {
  const { data } = await patch<UpdateEmployeeRolesPayload, string[]>(
    employeesEndpoint.id(employeeId).roles.url,
    roles
  );

  return data;
};

export const updateEmployeePermissions = async ({
  employee_id,
  permissions_ids,
}: UpdateEmployeePermissions): Promise<UpdatePermissions> => {
  const { data } = await patch<UpdatePermissions, number[]>(
    employeesEndpoint.id(employee_id).permissions.url,
    permissions_ids
  );

  return data;
};

export const updateEmployeeShortDescription = async ({
  employeeId,
  shortDescription,
}: EmployeeShortDescriptionProps): Promise<EmployeeShortDescriptionPayload> => {
  const { data } = await patch<EmployeeShortDescriptionPayload, string>(
    employeesEndpoint.id(employeeId).description.url,
    shortDescription
  );

  return data;
};

export const getFilterEmployeeParameters =
  async (): Promise<EmployeeFilterParams> => {
    const { data } = await get<EmployeeFilterParams>(
      employeesEndpoint.filterParams.url
    );

    return data;
  };

export const getEmployeeExport = async (
  params: EmployeeExportFields,
  employeeIdOrMe: EmployeeIdOrMe
): Promise<AxiosResponse<Blob>> => {
  return get(employeesEndpoint.id(employeeIdOrMe).export.url, {
    responseType: 'blob',
    params,
  });
};

export const getEmployeeProjectAllocations = async (
  employeeIdOrMe: EmployeeIdOrMe
): Promise<EmployeeProject[]> => {
  const response: AxiosResponse<EmployeeProject[]> = await get(
    employeesEndpoint.id(employeeIdOrMe).projectAllocations.url
  );
  const projects: EmployeeProject[] = response.data;
  return projects;
};

export const verifyEmployeeProfile = async (
  employeeId: number
): Promise<string> => {
  const { data } = await patch<string>(
    employeesEndpoint.id(employeeId).verify.url
  );

  return data;
};

export const notifyEmployeeToUpdateProfile = async (
  employeeId: number
): Promise<string> => {
  const { data } = await patch<string>(
    employeesEndpoint.id(employeeId).notify.url
  );

  return data;
};
