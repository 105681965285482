import { Box, Button, Img, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface PptExportButtonProps {
  text: string;
  imgSrc: string;
  onClick: () => void;
}

export const PptExportButton: React.FC<PptExportButtonProps> = ({
  text,
  imgSrc,
  onClick,
}) => (
  <Tooltip label={text}>
    <Button
      alignContent="center"
      as={Link}
      backgroundColor="white"
      border="1px"
      borderColor="gray.200"
      boxShadow="sm"
      color="gray.600"
      height="90px"
      marginLeft={2}
      padding={0}
      rounded={8}
      width="144px"
      _hover={{
        backgroundColor: 'white',
        borderColor: 'gray.400',
        boxShadow: 'xl',
        color: 'gray.800',
      }}
      type="submit"
      colorScheme="orange"
      shadow="sm"
      onClick={onClick}
      p={4}
    >
      <Box>
        <Img src={imgSrc} alt={text} w="127px" h="72px" />
      </Box>
    </Button>
  </Tooltip>
);
