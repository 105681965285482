import ChildrenList from './ChildrenList';
import PotentialChildrenList from './PotentialChildrenList';
import { Box, Heading, HStack, Stack, VStack } from '@chakra-ui/react';
import SearchInput from '~/shared/components/SearchInput';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import { useMapStore } from '~/shared/hooks/useMapStore';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface MapProps {
  childrenTitle: string;
  isLoadingChildren?: boolean;
  isLoadingPotentialChildren?: boolean;
  potentialChildrenTitle?: string;
}

export const Map: React.FC<MapProps> = ({
  childrenTitle,
  isLoadingChildren,
  isLoadingPotentialChildren,
  potentialChildrenTitle,
}) => {
  const { t } = useTranslation('base');
  const {
    addChild,
    children,
    deleteChild,
    isSearching,
    setQuery,
    shallSearch,
    shallRefetchOnSearch,
    getPotentialChildren,
    query,
    undoChildDeletion,
  } = useMapStore();

  const onSearch = useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery]
  );

  return (
    <HStack justifyContent="center" gap={5} align="start">
      <Stack w="350px" align="start">
        <Heading fontSize="sm" fontWeight="bold" mt="2" h="20px">
          {potentialChildrenTitle}
        </Heading>
        <VStack rounded={8} align="start" w="full">
          <Stack w="full" h="35px">
            <SearchInput
              bg="white"
              onChange={onSearch}
              rounded={8}
              shadow="sm"
              query={query}
            />
          </Stack>
          <Stack align="start" bg="gray.50" h="277px" rounded={8} w="full">
            <VStack p={2} overflow="auto" alignItems="initial" w="full">
              <Stack w="full">
                {!shallSearch && shallRefetchOnSearch ? (
                  <AlertInfo
                    text={t(
                      `map_modal.type_${!isSearching ? 'to_search' : 'more'}`
                    )}
                    status={!isSearching ? 'warning' : 'success'}
                  />
                ) : (
                  <PotentialChildrenList
                    data={getPotentialChildren()}
                    isLoading={isLoadingPotentialChildren}
                    onAdd={addChild}
                  />
                )}
              </Stack>
            </VStack>
          </Stack>
        </VStack>
      </Stack>
      <Stack align="start" w="350px">
        <Heading fontSize="sm" fontWeight="bold" h="20px" mt="2">
          {childrenTitle}
        </Heading>
        <Box bg="gray.50" h="320px" overflow="auto" px={2} rounded={8} w="full">
          <ChildrenList
            isLoading={isLoadingChildren}
            data={children}
            onDelete={deleteChild}
            onUndoDeletion={undoChildDeletion}
          />
        </Box>
      </Stack>
    </HStack>
  );
};
