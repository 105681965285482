import { useDisclosure, UseDisclosureReturn, useToast } from '@chakra-ui/react';
import { ChildStatus } from '~/shared/enums/childStatusColor';
import { useSaveProjectChildren } from '~/shared/hooks/project/useSaveProjectChildren';
import { useMapStore } from '~/shared/hooks/useMapStore';
import { Child, ChildRecord } from '~/shared/interfaces/map';
import { useGetProjectChildren } from '~/shared/queries/useGetProjectChildren';
import { useGetProjectPotentialChildren } from '~/shared/queries/useGetProjectPotentialChildren';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';

export interface UseProjectMapModal {
  children: ChildRecord[];
  confirmationModal: UseDisclosureReturn;
  isLoadingChildren: boolean;
  isLoadingPotentialChildren: boolean;
  isSaving: boolean;
  handleCancelConfirmation: () => void;
  handleClose: () => void;
  handleConfirmationSave: () => Promise<void>;
  handleSave: () => Promise<void>;
  wasChanged: boolean;
  potentialChildren: Child[];
  query?: string;
}

interface UseProjectMapModalProps {
  projectId?: number;
  onClose: () => void;
}

const useProjectMapModal = ({
  projectId,
  onClose,
}: UseProjectMapModalProps): UseProjectMapModal => {
  const {
    children,
    wasChanged,
    loadChildren,
    loadPotentialChildren,
    getPotentialChildren,
    query,
    resetMapState,
    setQuery,
  } = useMapStore();

  const confirmationModal = useDisclosure();
  const getProjectChildrenHook = useGetProjectChildren(projectId);
  const getPotentialProjectChildrenHook = useGetProjectPotentialChildren(
    projectId,
    query
  );
  const { saveProjectChildren } = useSaveProjectChildren();
  const toast = useToast();

  const closeModals = useCallback(() => {
    if (confirmationModal.isOpen) {
      confirmationModal.onClose();
    }

    onClose();
  }, [setQuery, confirmationModal]);

  const handleClose = () => {
    if (wasChanged) {
      confirmationModal.onOpen();
    } else {
      closeModals();
    }
  };

  const handleCancelConfirmation = () => {
    closeModals();
  };

  const handleSave = async () => {
    if (!projectId) {
      toast({
        title: t(`something_wrong`),
        description: t(`parent_id_missing`),
        status: 'error',
      });

      return;
    }

    const childrenIds = children
      .filter(({ status }) => status !== ChildStatus.REMOVED)
      .map(({ id }) => id);

    await saveProjectChildren.mutateAsync({ projectId, childrenIds });

    closeModals();
  };

  const handleConfirmationSave = async () => {
    await handleSave();
    closeModals();
  };

  useEffect(() => {
    if (getProjectChildrenHook.error) {
      toast({
        title: t(`something_wrong`),
        description: t(`project_children_load_fail`),
        status: 'error',
      });
    }
  }, [getProjectChildrenHook.error]);

  useEffect(() => {
    loadChildren(
      getProjectChildrenHook.data?.map(({ id, title }) => ({
        id,
        text: title,
      })) || []
    );
  }, [getProjectChildrenHook.data]);

  useEffect(() => {
    if (getPotentialProjectChildrenHook.error) {
      toast({
        title: t(`something_wrong`),
        description: t(`potential_children_load_fail`),
        status: 'error',
      });
    }
  }, [getPotentialProjectChildrenHook.error]);

  useEffect(() => {
    loadPotentialChildren(
      getPotentialProjectChildrenHook.data?.map(({ id, title }) => ({
        id,
        text: title,
      })) || []
    );
  }, [getPotentialProjectChildrenHook.data]);

  useEffect(() => {
    resetMapState();
  }, []);

  return {
    children,
    confirmationModal,
    isLoadingChildren: getProjectChildrenHook.isFetching,
    isLoadingPotentialChildren: getPotentialProjectChildrenHook.isFetching,
    wasChanged,
    isSaving: saveProjectChildren.isLoading,
    handleCancelConfirmation,
    handleClose,
    handleConfirmationSave,
    handleSave,
    potentialChildren: getPotentialChildren(),
  };
};

export default useProjectMapModal;
