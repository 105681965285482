import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatDate = (
  date: dayjs.ConfigType,
  format = 'YYYY-MM',
  defaultValue = ''
) => {
  return date ? dayjs(date).format(format) : defaultValue;
};

export const fileNameDate = () => {
  return formatDate(dayjs(), 'YYYY_MM_DD_HH_mm_ss');
};
